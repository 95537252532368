import React from "react"

/**
 * Catches errors in child components and displays a message
 * instead of blowing up the whole page.
 * 
 * See:
 * https://reactjs.org/blog/2017/07/26/error-handling-in-react-16.html
 */
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.errorMessage = props.errorMessage;
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });

    // Log error
    console.error(this.props.errorMessage);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <div></div>;
    }
    return this.props.children;
  }
}