import * as React from "react";
import ErrorBoundary from "./error-boundry";

/**
 * An animation for the flow summary page built with Flare and
 * utilizing flare-react.
 */
export default class FlowSummaryAnimation extends React.Component {
  constructor() {
    super();

    this.state = {
      animationWidth: null,
      animationHeight: null,
      animationComponent: null
    };
  }

  /**
   * Only load the component if we are in a browser environment.
   * This is necessary since you cannot turn of SSR support during
   * gatsby build and flare-react needs the window object.
   */
  async componentDidMount() {
    // load component
    const { default: animationComponent } = await import('flare-react');

    // get component width/height
    const { animationWidth, animationHeight } = this.getAnimationComponentSize();

    // update satate
    this.setState({ animationComponent, animationWidth, animationHeight });

    // update animation size on resize
    window.addEventListener('resize', () => {
      // get component width/height
      const { animationWidth, animationHeight } = this.getAnimationComponentSize();

      // update state
      this.setState({ animationComponent, animationWidth, animationHeight });
    });
  }

  /**
   * Calculate the proper size of the animation component based
   * on the browser width.
   */
  getAnimationComponentSize() {
    // set default values for animation container
    const animationAspectRatio = 950 / 400; // flare artboard size
    const defaultAnimationWidth = 1138;
    const defaultAnimationHeight = defaultAnimationWidth / animationAspectRatio;

    // width/height vars for animation container
    let animationWidth;
    let animationHeight;

    // Get the with for the animation container
    if (typeof window !== 'undefined' && window) {
      const vWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

      // if viewport greater than 950 then set width to 950
      if (vWidth > defaultAnimationWidth) {
        animationWidth = defaultAnimationWidth;
        animationHeight = defaultAnimationHeight;

        // if less than 950 set to full width and some padding
      } else {
        animationWidth = vWidth - 45;
        animationHeight = animationWidth / animationAspectRatio;
      }
    }

    return {
      animationWidth,
      animationHeight
    };
  }

  /**
   * Render the animation component inside of an error boundry to prevent
   * the page crashing in older browsers without WASM support
   */
  renderAnimationComponent() {
    return (
      <ErrorBoundary errorMessage="Animations are disabled: Unsupported Browser">
        {
          React.createElement(this.state.animationComponent, {
            width: this.state.animationWidth,
            height: this.state.animationHeight,
            animationName: "run",
            file: "/flare/Flow_Promo_Animated.flr",
            className: this.props.className
          })
        }
      </ErrorBoundary>
    );
  }

  /**
   * Render...
   */
  render() {
    return (
      (typeof window !== 'undefined' && window && this.state.animationComponent) && (
        this.renderAnimationComponent()
      )
    );
  }
}